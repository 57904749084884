import { useEffect, useRef, useState } from 'react';
import { Formik, FormikErrors, FormikHelpers, FormikTouched } from 'formik';

import { getCountryOriginBasedOnIp, myMemoize } from '../../GlobalFunctions';
import { signOutUser } from '../../UserManagementHelpers';
import { deleteCookie, getCookie, setCookie } from '../../aaa';
import { validateLicenseCode as vl } from '../../BackendInterface';

import { VerticalSpacer } from '../../core/core';
import SpinnerOverlay from '../../SpinnerOverlay';
import SignUpEpilogue from '../SignUpEpilogue';
import SignUpLegalese from '../SignUpLegalese/SignUpLegalese';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import LicenseCodeInput from '../LicenseCodeInput';

import utilityStyles from '../../SCSS/common/utilityStyles.module.scss';
import styles from './OauthLegaleseConfirmation.module.scss';

type Props = {
  onCopyUserInput: (arg0: Record<string, unknown>) => unknown;
  onNextStep: (arg0: number, arg1: boolean) => unknown;
  onSubmit: (arg0: Record<string, unknown>) => unknown;
  router: {
    replace: (arg0: string) => void;
  };
  userInput: {
    providerName: string;
    name: string;
    email: string;
    isOAuthSignUp: boolean;
  };
  query: URLSearchParams;
};

interface FormValues {
  licenseCode?: string;
  optIn: boolean;
  touPp: boolean;
}

const isFunction = function (obj: unknown) {
  return typeof obj === 'function';
};

/** @private is the given object an Object? */
const isObject = function (obj: unknown) {
  return obj !== null && typeof obj === 'object';
};

const isPromise = function (value: unknown) {
  return isObject(value) && isFunction(value.then);
};

const SignUpLegalesePanel = ({ onCopyUserInput, onNextStep, userInput, ...props }: Props): JSX.Element => {
  const { providerName, name, email, isOAuthSignUp } = userInput;
  const [isCheckingCode, setIsCheckingCode] = useState(false);
  const [isCheckingCountry, setCheckingCountry] = useState(true);
  const [isDefaultOptIn, setDefaultOptIn] = useState(false);
  const [cookieLicenseCode, setCookieLicenseCode] = useState('');
  const cache = useRef({});

  // console.debug(
  //   `%cOauthLegaleseConfirmation`,
  //   'background: blue; color: yellow'
  // );
  async function getCountry() {
    const country = await getCountryOriginBasedOnIp();
    country === 'United States' && setDefaultOptIn(true);
    setCheckingCountry(false);
  }

  async function getCode() {
    const licenseCode = getCookie('peekapak.redeemableLicenseCode');
    const displayCode = licenseCode ? licenseCode.match(/.{1,4}/g)!.join('-') : '';
    licenseCode && setCookieLicenseCode(displayCode);
  }

  const storeRedeemableLicenseCode = (codeToStore: string | undefined) => {
    if (codeToStore) {
      const cleaned = codeToStore.replace(/-/g, '');
      setCookie('peekapak.redeemableLicenseCode', cleaned);
    } else {
      deleteCookie('peekapak.redeemableLicenseCode');
    }
  };

  const validateTou = (touPp: boolean) => {
    if (touPp !== true) return `Please accept Peekapak’s Terms of Use and Privacy Policy`;

    return '';
  };

  const validateOptin = (optin: boolean) => '';

  async function _validateLicenseCode(code: string) {
    if (!code) return '';
    if (code.length < 14 && code.length > 0) return 'Please enter a complete license code';

    const cleanedCode = code.replace(/-/g, '');
    setIsCheckingCode(true);
    const valid = await vl(cleanedCode);
    setIsCheckingCode(false);

    if (valid) return '';
    return 'The license code entered is invalid';
  }

  const validateLicenseCode = myMemoize(cache.current, _validateLicenseCode);

  const validate = async (values: FormValues) => {
    // console.debug(
    //   `%cvalidate values = `,
    //   'background: black;color: red',
    //   values
    // );

    const errors: FormikErrors<FormValues> = {};

    const validators = {
      touPp: validateTou,
      optIn: validateOptin,
      licenseCode: validateLicenseCode,
    };

    for (const field of Object.keys(values)) {
      const data = values[field as keyof FormikErrors<FormValues>];
      const fn = validators[field as keyof FormikErrors<FormValues>];
      if (fn) {
        let validationResult;
        const maybePromise = fn(data);
        if (isPromise(maybePromise)) {
          validationResult = await maybePromise;
        } else {
          validationResult = maybePromise;
        }

        if (validationResult) errors[field as keyof FormikErrors<FormValues>] = validationResult;
      }
    }

    // console.debug(`%cError values`, 'background: violet; color: white', errors);
    return errors;
  };

  function merge(touched: FormikTouched<FormValues>, errors: FormikErrors<FormValues>) {
    const mergedErrors = { ...errors };
    for (const fieldName of Object.keys(errors)) {
      if (!touched[fieldName as keyof FormikTouched<FormValues>]) {
        mergedErrors[fieldName as keyof FormikErrors<FormValues>] = '';
      }
    }

    return mergedErrors;
  }

  const onSubmitIntercept = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (isCheckingCode) {
      return;
    }

    values.licenseCode && storeRedeemableLicenseCode(values.licenseCode);
    onCopyUserInput({
      name: name || '',
      email: email || '',
      optIn: values.optIn,
      touPp: values.touPp,
    });

    setSubmitting(false);
    onNextStep(0, true);
  };

  const onNoThanks = async () => {
    await signOutUser();
  };

  useEffect(() => {
    getCountry();
    getCode();
  }, []);

  const districtName = props?.query?.get('districtName');
  const licenseLevel = props?.query?.get('licenseLevel');
  const licenseLevelDisplayText = getLicenseDescription(licenseLevel);

  return (
    <>
      {isCheckingCountry && <SpinnerOverlay isShow={true} />}

      <div className={styles.signUpLegalesePanel}>
        <div className={`${utilityStyles.absCentre} ${styles.signUpPrologue}`}>
          <div className={styles.title}>{isOAuthSignUp ? 'Welcome!' : 'Almost done!'}</div>
          <div className={styles.subtitle}>
            Please confirm that you want to create a Peekapak account
            {isOAuthSignUp && ` using information from ${providerName || 'a third party'}`}:
          </div>
        </div>
        <div className={styles.panel}>
          <div className={styles.signUpInformation}>
            <div>
              <strong>Name:</strong> {name}
            </div>
            <div>
              <strong>Email:</strong> {email}
            </div>
            {districtName && licenseLevel && (
              <>
                <div>
                  <strong>District:</strong> {districtName}
                </div>
                <div>
                  <strong>License:</strong> {licenseLevelDisplayText}
                </div>
              </>
            )}
          </div>
          <Formik
            initialValues={{
              optIn: isDefaultOptIn || false,
              touPp: false,
              licenseCode: cookieLicenseCode,
            }}
            enableReinitialize
            validate={validate}
            onSubmit={onSubmitIntercept}
            validateOnChange={false}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              setFieldValue,
              setFieldTouched,
              handleBlur,
              isSubmitting,
            }) => {
              const mergedErrors = merge(touched, errors);

              const handleLicenseCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                const { name, value } = e.target;

                const toSetValue = (function () {
                  let beautified = value.toUpperCase();

                  if (beautified.length > 14) {
                    // user typed in max length, so no change
                    return beautified.substring(0, 14);
                  } else if (beautified.length === 0) {
                    // reset to blank
                    return '';
                  }

                  beautified = beautified.replace(/-/g, '');

                  if (beautified.length > 4) {
                    beautified = `${beautified.substring(0, 4)}-${beautified.substring(4)}`;
                  }

                  if (beautified.length > 9) {
                    beautified = `${beautified.substring(0, 9)}-${beautified.substring(9)}`;
                  }

                  return beautified;
                })();
                setFieldValue(name, toSetValue);
                setTimeout(() => setFieldTouched(name, true), 0);
              };
              return (
                <form onSubmit={handleSubmit}>
                  <SignUpLegalese
                    currentOptInState={values.optIn}
                    currentTouPpState={values.touPp}
                    onOptInChange={handleChange}
                    onTouPpChange={handleChangeImmediateFeedback}
                    touPpValidationHint={mergedErrors.touPp || ''}
                  />
                  {!districtName && (
                    <div
                      className={styles.licenseContainer}
                      style={{
                        border: cookieLicenseCode ? 'none' : 'solid 2px #ff6319',
                      }}
                    >
                      {cookieLicenseCode ? (
                        <p>You will be registered with this license code:</p>
                      ) : (
                        <p>Do you have a license code? Please enter it here:</p>
                      )}
                      <LicenseCodeInput
                        id='licenseCode'
                        name='licenseCode'
                        onChange={handleLicenseCodeChange}
                        onBlur={handleBlur}
                        value={values.licenseCode || ''}
                        disabled={cookieLicenseCode ? true : isCheckingCode}
                        isChecking={isCheckingCode}
                      />
                    </div>
                  )}
                  <VerticalSpacer ems={1} />
                  <div className={styles.buttons}>
                    <PrimaryButton secondary disabled={isSubmitting} onClick={onNoThanks} small>
                      No, thanks
                    </PrimaryButton>
                    <div
                      style={{
                        width: '2em',
                      }}
                    />
                    <PrimaryButton small disabled={isSubmitting} onClick={handleSubmit} type='submit'>
                      Get Started
                    </PrimaryButton>
                  </div>
                </form>
              );
              function handleChangeImmediateFeedback(e: React.ChangeEvent<HTMLInputElement>) {
                // store these values in the closure because the
                // synthetic event will be gone after this function
                // returns and the setTimeout handler won't be
                // able to access it anymore
                const { name, type, value, checked } = e.target;
                const toSetValue = (() => {
                  if (type === 'checkbox') return checked;
                  return value;
                })();
                setFieldValue(name, toSetValue);
                setTimeout(() => setFieldTouched(name, true), 0);
              }
            }}
          </Formik>
        </div>
        <SignUpEpilogue noPromoCode />
      </div>
    </>
  );
};

function getLicenseDescription(licenseLevel: string) {
  switch (licenseLevel) {
    case 'PRO':
    case 'CLASSROOM':
      return 'Pro subscription for Teachers';
    case 'SCHOOL_ADMINISTRATOR':
      return 'Pro subscription for School Administrators';
    case 'DISTRICT_ADMINISTRATOR':
      return 'Pro subscription for District Administrators';
    case 'LIBRARY':
      return 'Library subscription';
    case 'BASIC':
      return 'Free Basic access';
    default:
      return 'Basic access';
  }
}

export default SignUpLegalesePanel;
